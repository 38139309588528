@tailwind base;
@layer base {
  a { @apply text-cyan-700 font-bold }
  blockquote { @apply mb-2 italic }
  footer p { @apply mb-2 }
  h1, h2, h3, nav { @apply font-serif }
  h1, h2, h3 { @apply text-center }
  h2 { @apply text-3xl md:text-4xl 2xl:text-5xl mt-16 mb-8 md:mt-20 md:mb-10 }
  h3 { @apply text-2xl md:text-3xl 2xl:text-4xl mt-10 mb-5 md:mt-16 md:mb-8 }
  main h1 { @apply text-4xl md:text-5xl xl:text-6xl 2xl:text-7xl mt-16 mb-8 md:mt-20 md:mb-10 }
  main ul { @apply list-disc list-inside }
  nav li { @apply my-2 sm:mx-4 md:mx-6}
  p, main ul { @apply mb-4 }
}

@tailwind components;
@layer components {
  .bicb-bg { @apply bg-center bg-no-repeat bg-cover border-gray-200 border-y-2 }
  #bicb-bg-home {
    background-image: url('./img/black-isle-ceilidh-band--playing.png?as=webp&quality=80');
  }
  
  .bicb-caption { @apply italic text-center text-sm }
  
  .bicb-contact { @apply md:flex  md:justify-center }
  .bicb-contact > div { @apply mb-8 px-6 }
  
  .bicb-main { @apply container max-w-screen-lg md:leading-relaxed lg:leading-relaxed text-lg md:text-xl  }
  .bicb-main--larger { @apply pb-4 text-xl md:text-2xl xl:text-3xl }
  
  #contact-details #contact-link { @apply hidden }
}

@tailwind utilities;
